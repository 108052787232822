exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-generate-payment-code-js": () => import("./../../../src/pages/generate-payment-code.js" /* webpackChunkName: "component---src-pages-generate-payment-code-js" */),
  "component---src-pages-host-js": () => import("./../../../src/pages/host.js" /* webpackChunkName: "component---src-pages-host-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-old-js": () => import("./../../../src/pages/index-old.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-pages-number-generator-js": () => import("./../../../src/pages/number-generator.js" /* webpackChunkName: "component---src-pages-number-generator-js" */),
  "component---src-pages-orders-details-js": () => import("./../../../src/pages/orders/details.js" /* webpackChunkName: "component---src-pages-orders-details-js" */),
  "component---src-pages-orders-failure-js": () => import("./../../../src/pages/orders/failure.js" /* webpackChunkName: "component---src-pages-orders-failure-js" */),
  "component---src-pages-orders-success-js": () => import("./../../../src/pages/orders/success.js" /* webpackChunkName: "component---src-pages-orders-success-js" */),
  "component---src-pages-participant-js": () => import("./../../../src/pages/participant.js" /* webpackChunkName: "component---src-pages-participant-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-rules-js": () => import("./../../../src/pages/rules.js" /* webpackChunkName: "component---src-pages-rules-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-v-2-js": () => import("./../../../src/pages/v2.js" /* webpackChunkName: "component---src-pages-v-2-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

